body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #505050;
  line-height: 1.8;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

ul {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  display: block;
}

.menu {
  li {
    list-style: none;
  }
}

.container {
  max-width: 1040px;
}

.fs-7 {
  font-size: 13px;
}

.registration {
  background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url('images/bg.jpg') fixed center center;
  background-size: cover;
  padding: 120px 0;

  .email-user {
    position: relative;

    input {
      width: 100%;
      border: none;
      border-radius: 50px;
      padding: 12px 10px;
      outline: 0;
    }

    button {
      border: none;
      padding: 8px 30px;
      border-radius: 50px;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.ls-5 {
  letter-spacing: 5px;
  color: #fdb04b;
}
